<template>
    <div>
        <table class='table'>
            <thead>
                <tr>
                    <th>Location</th>
                    <th class="number">Current</th>
                    <th class="number">Reserved</th>
                    <th class="number">Total</th>
                    <th class="number">Consumed (last 365 days)</th>
                </tr>
            </thead>
            <tbody v-if='this.inventory'>
                <tr v-for='(inventory, index) in this.inventory' v-bind:key="index" >
                    <td>{{ inventory.cdi_location }}</td>
                    <td class="number">{{ inventory.current }}</td>
                    <td class="number">{{ inventory.reserved }}</td>
                    <td class="number">{{ inventory.total }}</td>
                    <td class="number">{{ inventory.consumed }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

export default {
    name: 'Inventory',
    props: ['inventory']
}

</script>