<template>
    <div>
        <h3>Inventory Adjustment</h3>
        <div class="col-md-3 col-sm-4 col-xs-6 form-group">
            <label>Location</label>
            <select v-model='cdiLocation' class='form-control'>
                <option v-for='[index, cdiLocation] in cache.cdiLocationCache.CDI_LOCATIONS' v-bind:key='index' :value='index'>{{ cdiLocation }}</option>
            </select>
        </div>
        <div class="col-md-3 col-sm-4 col-xs-6 form-group">
            <label>Quantity</label>
            <input type="text" v-model='qty' class="form-control">
        </div>
        <div class='col-md-3 col-sm-4 col-xs-6 form-group form_grid'>
            <input class='cdi_button btn btn-default' type='button' value='Save' @click='saveInventoryAdjustment' />
        </div>
    </div>
</template>

<script>

import { store } from '@/store/BusinessObject.store';
import cdiLocationCache from '@/cache/cdiLocation.cache';

export default {
    name: 'InventoryAdjustment',
    data() {
        return {
            cdiLocation:  1,
            qty: '',
            cache: {
                cdiLocationCache
            }
        }
    },
    methods: {
        saveInventoryAdjustment: function() {
            let options = {
                'params[cdi_location_id]': this.cdiLocation,
                'params[received_qty]': this.qty
            }
            store.api('save_inventory_adjustment', options, 'object.data');
            this.cdiLocation = 1;
            this.qty = '';
        }
    }
}

</script>