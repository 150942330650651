<template>
    <div>
        <div v-if="id">
            <h1>{{ state.object.data.raw_material }}</h1>
            <div class="spacing_btm">
                <ProtectedLink :displayInline="true" :type="'material'" :linkType="'button'" :classes="'btn btn-default '" :text="'Print Sticker'" v-show="isCurrentView('details')" :id="$data.state.object.id" :queryString="''" />
                <button class="btn btn-default" onclick="window.open('https://app.accrualify.com/catalogue_items', '_blank')">Corpay Catalog</button>
            </div>
            <Details :data="state.object.data" v-show="isCurrentView('details')" />
            <Inventory :inventory='state.object.data.inventory' v-show="isCurrentView('details')" />
            <InventoryAdjustment v-show="isCurrentView('details')" />
            <Prices :prices='state.object.data.prices' v-show="isCurrentView('prices')" />
            <Files :files='state.object.data.files' v-show="isCurrentView('files')" />
            <History :history='state.history' v-show="isCurrentView('history')" />
        </div>

        <New v-else-if="isCurrentView('new')" />
      <div v-else :showMoreButton="false" >

        <Search />
      </div>
    </div>
</template>

<script>

import { store } from '@/store/BusinessObject.store';
import Search from "@/components/businessObjects/utils/Search";
import New from "@/components/businessObjects/material/New";
import Details from "@/components/businessObjects/utils/Details";
import Prices from "@/components/businessObjects/material/Prices";
import Files from "@/components/businessObjects/material/Files";
import History from "@/components/businessObjects/utils/History";
import {materialStore} from "@/components/businessObjects/material/Material.store";
import Inventory from "@/components/businessObjects/material/Inventory";
import InventoryAdjustment from "@/components/businessObjects/material/InventoryAdjustment";
import ProtectedLink from "@/components/utils/ProtectedLink";

export default {
    name: 'Container',
    props: ['id'],
    components: {
        InventoryAdjustment,
        Inventory,
        Search,
        Details,
        Prices,
        Files,
        History,
        New,
        ProtectedLink
    },
    data() {
        return {
            state: store.state,
            materialState: materialStore.state,
        }
    },
    created() {
        store.initialize(this.materialState);
        this.load();
    },
    methods: {
        isCurrentView: function(view) {
            return view === this.state.currentView;
        },
        load: function() {
            if (this.id){
                store.load(this.id)
                    .then(() => this.$appStore.setTitle([this.state.object.data.raw_material, this.state.titlePlural]));
            }
        }
    },
    computed: {
        currentView: function() {
            return this.state.currentView;
        },
    },
    watch: {
        id: function (){
            this.load();
        },
        currentView: function() {
            if (this.state.currentView == 'history'){
                store.getHistory();
            } else if (this.state.currentView == 'prices'){
                store.api('get_prices_json', {}).then((result) => {
                        this.state.object.data.prices = result.prices;
                    });
            } else if (this.state.currentView == 'files') {
                store.api('get_files_json', {})
                    .then(result => this.state.object.data.files = result.files)
            } else if(this.currentView == 'search'){
                window.location.reload()
            }
        }
    }
}

</script>

<style>

</style>