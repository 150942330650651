<template>
    <div class='tabpane'>
        <div class="table-responsive">
            <table class='table'>
                <thead>
                <tr>
                    <th>File</th>
                    <th>From</th>
                    <th>Date</th>
                </tr>
                </thead>
                <tbody v-if='this.files'>
                <tr v-for='(file, index) in files' v-bind:key='index'>
                    <td>
                    <ProtectedLink :linkType="'link'" :text="file.cdi_file_title" :id="file.cdi_file_id" :queryString="queryString" :type="'download'" />
                    </td>
                    <td>{{ file.employee }}</td>
                    <td>{{ file.cdi_file_datetime }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import ProtectedLink from "@/components/utils/ProtectedLink";
import helpers from "@/store/helpers";

export default {
    name: "Files",
    components: {
        ProtectedLink
    },
    props: ['files'],
    computed: {
        baseUrl: function() {
            return process.env.VUE_APP_URL
        },
        queryString: function() {
            const params = helpers.getQSString({ 'params' : this.companyId});
            return `genid=${Math.floor(Math.random()*101)}&${params}`;
        }
    }
}
</script>

<style scoped>

</style>