<template>
    <div>
        <div class="row">
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Company</label>
                <Typeahead ref='companyTypeahead' cdi_type="company" v-bind:ID.sync="company" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Price</label>
                <input type=text v-model='price' class="form-control">
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Lead Time</label>
                <input type=text v-model='leadTime' class="form-control">
            </div>
        </div>
        <input class='btn btn-default cdi_button' type=button @click='savePrice' value='Save Price'>
        <div class="spacing_top table-responsive" >
            <table class='table'>
                <thead>
                    <tr>
                        <th>Company</th>
                        <th class='number' >Price</th>
                        <th class='number' >Lead Time</th>
                    </tr>
                </thead>
                <tbody >
                <tr v-for='(price) in this.prices' v-bind:key='price.raw_material_price_id' >
                    <td>{{ price.company_name }}</td>
                    <td class='number'>{{ price.company_price }}</td>
                    <td class='number'>{{ price.lead_time }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

import { store } from '@/store/BusinessObject.store';
import Typeahead from "@/components/utils/typeaheads/Typeahead";

export default {
    name: 'Prices',
    components: {Typeahead},
    props: ['prices'],
    data() {
        return {
            state: store.state,
            company: 0,
            price: '',
            leadTime: ''
        }
    },
    methods: {
        savePrice: function() {
            let params = {
                'params[company_id]': this.company,
                'params[company_price]': this.price,
                'params[lead_time]': this.leadTime
            };
            store.api('save_price', params).then( (result) => {
                    this.state.object.data.prices = result.prices;
                }
            );
            this.$refs.companyTypeahead.$data.object = '';
            this.price = '';
            this.leadTime = ''
        }
    }
}

</script>