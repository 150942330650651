import cdiLocationCache from '@/cache/cdiLocation.cache'
import materialCache from '@/cache/material.cache';
import appStore from '@/store/App.store';

export const materialStore = {
	state: {
		name: 'material',
		title: 'Material',
		titlePlural: 'Materials',
        titleAlt: 'Purchased Items',
		nav: {
			base: 'material',
			newView: true,
			views: [
				{ view: 'details', label: 'Details' },
				{ view: 'prices', label: 'Prices' },
				{ view: 'files', label: 'Files' },
				{ view: 'history', label: 'History', },
			],
		},
		search: {
			endPoint: 'materials',
			formFields: {
				categoryName: {
					val: '4',
					qsField: '[numbers][rm.inventory_category_id]',
					label: 'Category',
					component: 'SearchSelectInput',
					options: materialCache.MATERIAL_CATEGORIES,
				},
				name: {
					val: '',
					qsField: '[fstrings][raw_material]',
					label: 'Name',
					component: 'SearchTextInput'
				},
				location: {
					val: appStore.state.user.defaultLocationID,
					qsField: '[cdi_location_id]',
					label: 'Location',
					component: 'SearchSelectInput',
					options: cdiLocationCache.CDI_LOCATIONS
				},
				hideDisabled: {
					val: '1',
					qsField: '[isnot][disable]',
					label: 'Hide Disabled',
					component: 'SearchCheckboxInput'
				},
                searchIndex: {val: 0, qsField: '[search_index]'},
			},
			resultFields: {
				raw_material_id: {header: 'ID', link: 'material'},
				raw_material: {header: 'Material', link: ''},
				job_cost: {header: 'Job Cost', link: ''},
				inventory_unit: {header: 'Inv. Unit', link: ''},
				current: {header: 'Inv', link: ''},
				inventory_category: {header: 'Category', link: ''},
			}
		},
		object: {
			fields: [
				{
					name: 'raw_material_id',
					label: 'Material ID',
					component: 'DetailsStatic'
				},
				{
					name: 'raw_material',
					label: 'Material',
					component: 'DetailsText'
				},
				{
					name: 'inventory_category_id',
					label: 'Category',
					component: 'DetailsSelect',
					options: materialCache.MATERIAL_CATEGORIES
				},
				{
					name: 'unit_size',
					label: 'Unit Size',
					component: 'DetailsText'
				},
				{
					name: 'cost',
					label: 'Cost (per Unit Size)',
					component: 'DetailsText'
				},
				{
					name: 'inventory_unit',
					label: 'Inventory Unit',
					component: 'DetailsSelect',
					options: materialCache.Material_Measurement_Units
				},
				{
					name: 'item_weight',
					label: 'Weight (lbs)',
					component: 'DetailsText'
				},
				{
					name: 'track_inventory',
					label: 'Track Inventory',
					component: 'DetailsCheckbox'
				},
				{
					name: 'manage_inventory',
					label: 'Manage Inventory',
					component: 'DetailsCheckbox'
				},
				{
					name: 'track_fifo',
					label: 'Track in FIFO',
					component: 'DetailsCheckbox'
				},
				{
					name: 'pack_size',
					label: 'Pack Size',
					component: 'DetailsText'
				},
				{
					name: 'buffer_pct_override',
					label: 'Buffer % Override',
					component: 'DetailsText'
				},
				{
					name: 'bin_size',
					label: 'Bin Size',
					component: 'DetailsText'
				},
				{
					name: 'disable',
					label: 'Disable',
					component: 'DetailsCheckbox'
				},
				{
					name: 'general_ledger_account_id',
					label: 'General Ledger Acct.',
					component: 'DetailsSelect',
					options: [[0,'None']],
				},
				{
					name: 'corpay_id',
					label: 'Corpay ID',
					component: 'DetailsText',
				}
			]
		},
	}
}
