<template>
    <div>
        <div class="row">
            <h1>Purchased Items</h1>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>New Material</label><br>
                <input type="text" v-model="rawMaterial" class="form-control">
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Category</label><br>
                <select v-model="category" class="form-control">
                    <option v-for="[index, option] in this.cache.materialCache.MATERIAL_CATEGORIES" v-bind:key="index" :value="index">{{ option }}</option>
                </select>
            </div>
        </div>
        <input type="button" class="cdi_button btn btn-default" @click="saveNewMaterial" value="Add Material">
    </div>
</template>

<script>

import { store } from '@/store/BusinessObject.store';
import materialCache from '@/cache/material.cache';

export default {
    name: 'New',
    data() {
        return {
            state: store.state,
            rawMaterial: '',
            category: 4,
            cache: {
                materialCache
            }
        }
    },
    methods: {
        saveNewMaterial: function() {
            this.state.object.id = 0;
            let params = {
                'params[raw_material]' : this.rawMaterial,
                'params[inventory_category_id]' : this.category
            }
            store.api('new_raw_material', params).then(result =>
                this.$router.push(`/material/${result.raw_material_id}`)
            );
        }
    }
}

</script>